// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");

$(document).ready(function ()
{
	$('.go-up').click(function(){
		$('html,body').animate({scrollTop: 0}, 300);
		return false;
	});


	/*
	 * Productfiche
	 */
	 $('#product_form').submit(function(e)
	 {
	 	e.preventDefault();

	 	var pdf = $(this).attr('data-pdf');

	 	var confirm = $(this).find("#confirm").val();
	 	var email = $(this).find("#email").val();

		$('#product_form .alert').addClass('d-none');

	 	$.ajax({
			url: "/api/v2/1/nl/subscribe/2",
			method: "POST",
			data: { email : email },
		})
		.done(function( msg ) {
			$('#product_form').addClass('d-none');
			$('#product_form_success').removeClass('d-none');
		})
		.fail(function( jqXHR, textStatus ) {
			$('#product_form .alert').removeClass('d-none');
		});
	 })




	/*
	 *	MOBILE NAV
	 */

	$('.subnav').hide();

	$('.subnav-toggler').click(function(){
		$(this).toggleClass('toggled');
		$('.subnav', $(this).parent()).slideToggle(200);
	});

	/*
	 *	SLIDERS
	 */
	$('.slider-logos').slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
  		autoplaySpeed: 8000,
  		prevArrow: '<button type="button" class="slick-prev" aria-label="previous"><i class="icon-angle-left"></i></button>',
  		nextArrow: '<button type="button" class="slick-next" aria-label="next"><i class="icon-angle-right"></i></button>',
  		responsive: [
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 3,
			}
		},
		{
			breakpoint: 575,
			settings: {
				slidesToShow: 2,
			}
		}]
	});

	$('.slider-detail').slick({
		infinite: true,
  		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
  		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>'
	})
	.on('beforeChange', function(event, slick, currentSlide, nextSlide){
		$('.detail-thumb').removeClass('active');
		$('.detail-thumb').eq(nextSlide).addClass('active');
	});

	$('.detail-thumb').click( function(){
		index = $(this).index();
		$('.slider-detail').slick('slickGoTo', parseInt(index));
	});

	$('.banner-slider').on("init", function (event, slick) {
		let player = $(slick.$slider).find(".slick-current").find("iframe").get(0);
		if(player){
			player.src = player.src.replace('autoplay=0', 'autoplay=1');
		}
	}).slick({
		infinite: true,
  		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
  		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>'
	}).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		console.log('beforeChange');
		let player = $(slick.$slider).find(".slick-current").find("iframe").get(0);
		if (player){
			player.contentWindow.postMessage(JSON.stringify({
				"method": "pause",
				"value": 1
			}), "*");
		}
	}).on("afterChange", function (event, slick, currentSlide) {
		console.log('afterChange');
		let player = $(slick.$slider).find(".slick-current").find("iframe").get(0);
		if(player){
			player.contentWindow.postMessage(JSON.stringify({
				"method": "play",
				"value": 1
			}), "*");
		}
	});

	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			media : {}
		},
	});

	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
	$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			if( src.indexOf('?') > -1 )
			{
				iframe.attr('src', src + '&autoplay=1');
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});
});



function noResultsCheck(event, laidOutItems) {
	var numItems = laidOutItems.length;

	/*
	if (numItems == 0) {
		$('#no-results').show();
	} else {
		$('#no-results').hide();
	}*/
}





$(window).on('load', function ()
{
	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();


	/*
	 *	GRID + FILTER
	 */

	var $grid = $('#grid-videos');

	$grid.on('layoutComplete', noResultsCheck);

	$grid.isotope({
		itemSelector: '.grid-item',
		layoutMode: 'fitRows',
		percentPosition: true,
		masonry: {
			columnWidth: '.grid-item',
			gutter: 0
		},
	});

	$('#filter-videos').on('change', function(e) {
		var cat = $('#filter-videos option:selected').attr('value');
		
		if (cat == -1) {
			$grid.isotope({filter: '*'});
		} else {
			$grid.isotope({filter: '.'+cat});
		}
	});
});



// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response)
{
	captcha_validated = true;
	$('.rc-form form').submit();
}

function validateForm(event)
{
	event.preventDefault();
	var form = this;

	var validator = $(form).data('bs.validator');
	validator.validate();

	if (captcha_validated && !validator.hasErrors()) {
		form.submit();
	} else if(!captcha_validated) {
		grecaptcha.reset();
		grecaptcha.execute();
	}
}